.options-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.options-container::-webkit-scrollbar {
  display: none;
}

.option:hover {
  background-color: #f5f5f5;
}
