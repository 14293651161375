.container {
  height: fit-content;
  padding: 0.375rem 0.75rem;
}

.container:focus-within {
  border-color: #d88c99 !important;
  box-shadow: 0 0 0 0.2rem rgba(176, 25, 51, 0.25);
}

.container.valid {
  border-color: var(--bs-success) !important;
}

.container.valid:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
  grid-template-columns: 0.75rem 1fr 2.75rem;
}

.container.invalid {
  border-color: var(--bs-danger) !important;
}

.container.invalid:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
  grid-template-columns: 0.75rem 1fr 2.75rem;
}

.text-area {
  display: block;
  background-color: transparent;
  padding: 0;
  border-style: none;
  outline-style: none;
  /* resize: none; */
  scrollbar-width: none;
}

.text-area::-webkit-scrollbar {
  display: none;
}

.icon {
  font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
  font-size: 1.5rem;
}
