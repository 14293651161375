@keyframes toast-show {
  0% {
    top: 20%;
  }
  70% {
    top: -10px;
  }
  90% {
    top: 10px;
  }
  100% {
    top: 0;
  }
}

@keyframes toast-close {
  0% {
    top: 0;
  }
  10% {
    top: 10px;
  }
  30% {
    top: -10px;
  }
  100% {
    top: 20%;
  }
}
