.scan-region-highlight-svg {
  stroke: rgb(255, 206, 85) !important;
  stroke-width: 2.5 !important;
}

.code-outline-highlight {
  stroke: rgb(255, 206, 85) !important;
  stroke-width: 5 !important;
}

.traslate-left-50 {
  transform: translate(-50%, 0);
}
