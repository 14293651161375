.outer-container {
  background-color: white;
}

.inner-container {
  width: calc(min(100%, 400px));
  border-width: 0;
  border-color: var(--bs-border-color);
  border-style: solid;
}

@media (min-width: 576px) {
  .outer-container {
    background-color: transparent;
  }

  .inner-container {
    border-width: 1px;
  }
}
