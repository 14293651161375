.actions {
  border-style: none;
  outline-style: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}

.actions:hover {
  background-color: var(--bs-tertiary-bg);
}

.actions:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 25, 51, 0.25);
  background-color: var(--bs-tertiary-bg);
}

.actions:active {
  background-color: var(--bs-secondary-bg);
}
