.search-bar:focus-within {
  border-color: #d88c99 !important;
  box-shadow: 0 0 0 0.2rem rgba(176, 25, 51, 0.25);
}

.search-input {
  border: none;
  outline: none;
  min-width: 0;
}

.search-button:focus {
  box-shadow: none !important;
}
