.created {
  top: 20%;
}

.displaying :global {
  animation: 1s linear forwards toast-show;
}

.displayed {
  top: 0;
}

.closing :global {
  animation: 1s linear forwards toast-close;
}
