.reveal {
  border-style: none;
  outline-style: none;
  background-color: transparent;
}

.icon {
  color: var(--bs-tertiary-color);
  font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
  font-size: 1.5rem;
}

.reveal:hover .icon,
.reveal:focus .icon {
  color: var(--bs-secondary-color);
}

.reveal:active .icon {
  color: var(--bs-tertiary-color);
}
